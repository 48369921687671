const _ = require('lodash');
const $ = require('jquery');
require('jquery-ui/ui/widgets/autocomplete');
require('jquery-ui/ui/widgets/slider');
require('jquery-ui-touch-punch');
const api = require('../utils/api');
const selectOptions = require('../utils/select-options');

const dmpApp = window.dmpApp;

const $document = $(document);

function addThousandsSeparator(value, separator = '.') {
    if (!separator) {
        return value;
    }

    return ('' + value).split('.')[0].split('').reverse().join('').match(/\d{1,3}/g).join('.').split('').reverse().join('');
}

function initRangeSlider(sliderSelector) {
    const sliderInput = document.querySelector(sliderSelector);

    if (sliderInput === null) {
        return;
    }

    const $input = $(sliderInput);

    const min = 1 * $input.attr('data-min');
    const max = 1 * $input.attr('data-max');
    const value = sliderInput.value.split('-');

    const $slider = $input.next('.slider');
    const $sliderControl = $slider.find('.slider__control');
    const [minLabel, maxLabel] = $slider.find('.slider__labels span').toArray();

    const minTemplate = _.template($slider.data('minLabel'));
    const maxTemplate = _.template($slider.data('maxLabel'));
    const maxLimit = $slider.data('maxLimit');
    const maxLimitTemplate = _.template($slider.data('maxLimitLabel'));
    const thousandsSeparator = $slider.data('thousandsSeparator') || '';

    function updateLabels(lower, upper) {
        minLabel.textContent = minTemplate({ value: addThousandsSeparator(lower, thousandsSeparator) });
        maxLabel.textContent = (maxLimit && upper >= maxLimit ? maxLimitTemplate : maxTemplate)({ value: addThousandsSeparator(upper, thousandsSeparator) });
    }

    $sliderControl.on('slidecreate', (event, ui) => {
        const values = $sliderControl.slider('values');

        updateLabels(...values);
    }).on('slide', (event, ui) => {
        const value = [
            ui.values[0] === min ? '' : ui.values[0],
            ui.values[1] === max ? '' : ui.values[1]
        ];

        sliderInput.disabled = value.filter((val) => val).length === 0;
        sliderInput.value = !sliderInput.disabled ? value.join('-') : '';

        updateLabels(...ui.values);
    });

    $sliderControl.slider({
        range: true,
        min,
        max,
        step: (1 * $input.attr('data-step')) || 1,
        values: [value[0] || min, value[1] || max]
    });
}

function initAutocomplete(selector, fillsTargetSelector = null, remote = null) {
    const autocompleteInput = document.querySelector(selector);

    if (!autocompleteInput) {
        return;
    }

    remote = autocompleteInput.dataset.remote || remote;

    if (!remote) {
        return;
    }

    const $input = $(autocompleteInput);
    const target = document.querySelector(fillsTargetSelector);
    const $target = $(target);

    const cache = {};
    let currentRequest;

    $input.autocomplete({
        appendTo: $input.closest('.form-group'),
        minLength: 0, // 2
        source: ({ term }, response) => {
            if (term in cache) {
                response(cache[term]);
                return;
            }

            currentRequest = api.get(remote, {q: term});
            currentRequest.then(({ data }) => {
                response(data);
            }).catch(() => {}).finally(() => {
                currentRequest = null;
            });
        },

        focus: (event, ui) => {
            event.preventDefault();

            // `event` is triggered by the autocomplete widget
            // - which encapsulates the menu widget event as originalEvent
            // - which encapsulates the jQuery event as originalEvent
            // - which encapsulates the real event as originalEvent

            if (/^key/.test(event.originalEvent.originalEvent.type)) {
                autocompleteInput.value = ui.item.label;
            }
        },
        search: (event/*, ui*/) => {
            if (currentRequest) {
                currentRequest.cancel();
                currentRequest = null;
            }

            target.value = '';
            $target.trigger('change');
            target.disabled = true;

            if (_.trimStart(autocompleteInput.value).length < 2) {
                event.preventDefault();

                autocomplete.close();
            }
        },
        select: (event, ui) => {
            event.preventDefault();

            autocompleteInput.value = ui.item.label;
            target.value = ui.item.value;
            target.disabled = false;
            $target.trigger('change');
        }
    });

    const autocomplete = $input.autocomplete('instance');
}

function initCityDistance() {
    const cityCodeSelector =  '#city_code';
    const distanceSelector = '#distance';
    const dealerGroupSelector = '#dealer_group';

    const cityCodeInput = document.querySelector(cityCodeSelector);
    const distanceInput = document.querySelector(distanceSelector);
    const dealerGroupInput = document.querySelector(dealerGroupSelector);

    if (!cityCodeInput || !distanceInput || !dealerGroupInput) {
        return;
    }

    const closestDealerGroupsRemote = distanceInput.dataset.remote;

    const $cityCodeInput = $(cityCodeInput);
    const $distanceInput = $(distanceInput);
    const $dealerGroupInput = $(dealerGroupInput);

    const cache = {};
    let currentRequest;

    $document.on('change', cityCodeSelector, (event) => {
        const enable = cityCodeInput.value;

        distanceInput.disabled = !enable;
        $distanceInput.trigger($.Event(enable ? 'enable' : 'disable'));
    }).on('disable', distanceSelector, (event) => {
        if (currentRequest) {
            currentRequest.cancel();
            currentRequest = null;
        }

        selectOptions.restoreAll(dealerGroupInput);
        $dealerGroupInput.trigger($.Event('change'));
    }).on('change enable', distanceSelector, (event) => {
        if (currentRequest) {
            currentRequest.cancel();
            currentRequest = null;
        }

        const cityCode = cityCodeInput.value;
        const distance = $distanceInput.val();

        const cacheKey = `${cityCode}:${distance}`;

        if (cacheKey in cache) {
            closestDealerGroupsFetched(cache[cacheKey], dealerGroupInput);
            return;
        }

        currentRequest = api.get(closestDealerGroupsRemote, {
            city_code: cityCode,
            distance
        });

        currentRequest.then(({ data }) => {
            cache[cacheKey] = data;
            closestDealerGroupsFetched(data, dealerGroupInput);
        }).catch(() => {}).finally(() => {
            currentRequest = null;
        });
    });

    if (cityCodeInput.value) {
        $cityCodeInput.trigger($.Event('change'));
    }
}

function closestDealerGroupsFetched(data, element) {
    const dealerGroupRegExp = data.length ? new RegExp(`^(\\d{5})*(${data.join('|')})(\\d{5})*$`) : /^$/;

    selectOptions.restore(element, (option) => {
        if (_.isNil(option.value) || option.value === '') {
            return true;
        }

        return dealerGroupRegExp.test(option.value);
    });

    $(element).trigger($.Event('change', {
        dealers: data
    }));
}

function initDealersSelect(groupSelector, selector) {
    const groupInput = document.querySelector(groupSelector);
    const input = document.querySelector(selector);

    if (!groupInput || !input) {
        return;
    }

    selectOptions.init(groupInput);
    selectOptions.removeHiddens(groupInput);

    selectOptions.init(input);
    selectOptions.removeHiddens(input);

    $document.on('change', groupSelector, (event) => {
        const dealerGroup = $(event.currentTarget).val().match(/\d{5}/g);

        if (!dealerGroup) {
            input.disabled = true;

            $(input).val('');

            return;
        }

        const dealers = event.dealers;

        selectOptions.restore(input, (option) => {
            if (_.isNil(option.value) || option.value === '') {
                return true;
            }

            if (dealers && !dealers.includes(option.value)) {
                return false;
            }

            return dealerGroup.includes(option.value);
        });

        input.disabled = false;
    });
}

function initSorting(sortingSelector, submit) {
    const sortingInput = document.querySelector(sortingSelector);

    if (!sortingInput || !sortingInput.name) {
        return;
    }

    let form = sortingInput.getAttribute('data-form');

    if (form) {
        form = document.getElementById(form);
    }

    if (!form) {
        return;
    }

    const targetInput = form.querySelector(`[name="${sortingInput.name}"]`);

    if (!targetInput) {
        return;
    }

    $document.on('change', sortingSelector, (event) => {
        targetInput.value = event.currentTarget.value;

        if (submit) {
            form.submit();
        }
    });
}

function initDilSearchSignals() {
    if (!dmpApp) {
        return;
    }

    if (!window.trackSearchSignals) {
        return;
    }

    const signalsSource = {
        'c_usato_search-city': '#city_zip',
        'c_usato_search-brand': '#brand',
    };

    dmpApp.aamOnReady(() => {
        if (!window.bmwDil) {
            return;
        }

        const signals = {};

        Object.entries(signalsSource).forEach(([signal, source]) => {
            let value;

            if (_.isString(source)) {
                value = (document.querySelector(source) || {}).value;
            }

            if (!(_.isNil(value) || value === '')) {
                signals[signal] = value;
            }
        });

        window.bmwDil.api.signals(signals);
    });
}

function init() {
    initDilSearchSignals();

    initRangeSlider('#price');
    initRangeSlider('#mileage');
    initRangeSlider('#registration_year');

    initAutocomplete('#city_zip', '#city_code');

    initDealersSelect('#dealer_group', '#dealer');

    initCityDistance();

    initSorting('#sorting', true);
}

export { init };
