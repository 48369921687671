/**
 * First, we will load all of this project's Javascript utilities and other
 * dependencies. Then, we will be ready to develop a robust and powerful
 * application frontend using useful Laravel and JavaScript libraries.
 */

require('./bootstrap');

const filters = require('./used-cars/filters');
const detail = require('./used-cars/detail');

const dmpApp = window.dmpApp;

(function() {
    filters.init();
    detail.init();

    if (dmpApp) {
        dmpApp.aamOnReady(() => {
            dmpApp.dilSubmitData().adformTrack();
        });
    }
})();
