const $ = require('jquery');

const stepGroupSelector = '.step-group';
const stepSelector = '.step';
const stepGroupAttribute = 'data-step-group';
const stepAttribute = 'data-step';
const activeClass = 'active';

function getGroup(element) {
    return $(element).closest(`${stepGroupSelector}[${stepGroupAttribute}]`)[0];
}

function getStep(group, element) {
    return $(element).closest(`${stepSelector}[${stepGroupAttribute}="${group.getAttribute(stepGroupAttribute)}"]`)[0];
}

function getCurrent(group) {
    return group.querySelector(`${stepSelector}.${activeClass}[${stepGroupAttribute}="${group.getAttribute(stepGroupAttribute)}"]`);
}

function change(group, name) {
    const step = group.querySelector(`${stepSelector}:not(.${activeClass})[${stepGroupAttribute}="${group.getAttribute(stepGroupAttribute)}"][${stepAttribute}="${name}"]`);

    if (!step) {
        // todo throw

        return;
    }

    const current = getCurrent(group);

    if (current) {
        current.classList.remove(activeClass);
    }

    step.classList.add(activeClass);
}

export {
    getGroup,
    change
};
