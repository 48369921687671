const loadingClass = 'loading';

function setLoadingState(element = document.body) {
    element.classList.add(loadingClass);
}

function removeLoadingState(element = document.body) {
    element.classList.remove(loadingClass);
}

// add / remove loadingClass by promise result (any result)
function onPromise(promise, element = document.body) {
    setLoadingState(element);

    promise.catch(() => {}).finally(() => {
        removeLoadingState(element);
    });
}

export {
    setLoadingState,
    removeLoadingState,

    onPromise
};
