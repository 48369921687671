function getElement(selector) {
    return typeof selector === 'string' ? document.querySelector(selector) : selector;
}

function removeProperty(element, property, attribute) {
    if (property) {
        element[property] = null;
    }

    if (attribute) {
        element.removeAttribute(attribute);
    }
}

function storeCachedFragment(select) {
    const fragment = document.createDocumentFragment();

    Array.from(select.querySelectorAll('option')).forEach((node) => {
        const clone = node.cloneNode(true);

        if (clone.nodeType === Element.ELEMENT_NODE) {
            removeProperty(clone, 'hidden', 'hidden');
            removeProperty(clone, 'disabled', 'disabled');
            removeProperty(clone, 'selected', 'selected');
        }

        fragment.appendChild(clone);
    });

    select.cachedFragment = fragment;
}

function removeHiddens(select) {
    Array.from(select.querySelectorAll('option[hidden]')).forEach((option) => {
        option.parentNode.removeChild(option);
    });
}

function restore(select, callback, keepSelection = true) {
    if (!('cachedFragment' in select)) {
        return;
    }

    const fragment = select.cachedFragment.cloneNode(true);

    if (callback !== true) {
        Array.from(fragment.querySelectorAll('option')).forEach((option, i) => {
            if (!callback(option, i)) {
                option.parentNode.removeChild(option);
            }
        });
    }

    const value = Array.from(select.querySelectorAll('option')).reduce((value, option) => {
        if (value) {
            return value;
        }

        if (option.selected && !option.disabled) {
            return option.value;
        }

        return null;
    }, null);

    while (select.hasChildNodes()) {
        const node = select.lastChild;

        select.removeChild(node);
    }

    if (value) {
        const previousSelected = fragment.querySelector(`option[value="${value}"]`);

        if (previousSelected) {
            previousSelected.selected = true;
        }
    }

    select.appendChild(fragment);
}

function restoreAll(select, keepSelection = true) {
    restore(select, true, keepSelection);
}

function remove(selector, callback = true) {
    const select = getElement(selector);

    if (callback === true) {
        while (select.hasChildNodes()) {
            const node = select.lastChild;

            select.removeChild(node);
        }
    } else {
        Array.from(select.querySelectorAll('option')).forEach((option, i) => {
            if (callback(option, i)) {
                option.parentNode.removeChild(option);
            }
        });
    }
}

function clear(selector) {
    remove(selector, (option) => !!option.value);
}

function set(selector, options) {
    const select = getElement(selector);

    clear(select);

    const fragment = document.createDocumentFragment();

    if (Array.isArray(options)) {
        options.forEach((opt) => {
            const option = document.createElement('option');

            option.value = opt.value;
            option.text = opt.label;

            fragment.appendChild(option);
        });
    } else {
        Object.keys(options).forEach((value) => {
            const option = document.createElement('option');

            option.value = value;
            option.text = options[value];

            fragment.appendChild(option);
        })
    }

    select.appendChild(fragment);
}

function get(selector, callback = true) {
    const select = getElement(selector);

    const options = [];

    if (callback === false) {
        callback = (option, i) => !!option.value;
    }

    if (callback === true) {
        Array.from(select.querySelectorAll('option')).forEach((option, i) => {
            options.push({
                value: option.value,
                label: option.text
            });
        });
    } else {
        Array.from(select.querySelectorAll('option')).forEach((option, i) => {
            if (callback(option, i)) {
                options.push({
                    value: option.value,
                    label: option.text
                });
            }
        });
    }

    return options;
}

function init(selector) {
    const select = getElement(selector);

    storeCachedFragment(select);
}

export {
    init,
    remove,
    clear,
    set,
    get,
    removeHiddens,
    restoreAll,
    restore
};
