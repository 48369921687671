const _ = require('lodash');
const axios = require('axios');

const CancelToken = axios.CancelToken;

function send(config) {
    return axios(config);
}

function cancelable(config) {
    const source = CancelToken.source();

    config.cancelToken = source.token;

    const request = send(config);

    request.cancel = source.cancel;

    return request;
}

function get(url, params = {}, responseType = 'json') {
    return cancelable({
        url,
        method: 'get',
        params,
        responseType
    });
}

function post(url, data = {}, responseType = 'json') {
    return cancelable({
        url,
        method: 'post',
        data,
        responseType
    });
}

export {
    get,
    post
};
