const $ = require('jquery');
const Swiper = require('swiper/js/swiper.js');
const YouTubePlayer = require('youtube-player');
const form = require('../utils/form');
const step = require('../utils/step');
const loading = require('../utils/loading');

const dmpApp = window.dmpApp;

Promise; // <-- force polyfill :/

const $document = $(document);
let thumbsGallery;
let mainGallery;

function initGallery(mainSelector, thumbsSelector) {
    thumbsGallery = document.querySelector(thumbsSelector);
    mainGallery = document.querySelector(mainSelector);

    if (mainGallery === null || thumbsGallery === null) {
        return;
    }

    thumbsGallery = new Swiper(thumbsGallery, {
        init: false,
        spaceBetween: 5,
        slidesPerView: 3,
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
    });

    mainGallery = new Swiper(mainGallery, {
        spaceBetween: 5,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    mainGallery.on('slideChange', function() {
        thumbsGallery.slideTo(this.activeIndex - 1, thumbsGallery.params.speed, false);
    });

    thumbsGallery.on('slideChange', function() {
        mainGallery.slideTo(this.activeIndex + 1, mainGallery.params.speed, true);
    }).on('init', function() {
        $(thumbsGallery.$wrapperEl[0]).on('click', `.${thumbsGallery.params.slideClass}`, (event) => {
            for (let i = 0; i < thumbsGallery.slides.length; i++) {
                if (thumbsGallery.slides[i] === event.currentTarget) {
                    mainGallery.slideTo(i, mainGallery.params.speed, true);
                    break;
                }
            }
        });
    });

    thumbsGallery.init();
}

const ytMediaSelector = '[data-media-type="youtube-video"]';

function getYtMediaWrap(element) {
    return element.querySelector(ytMediaSelector);
}

function injectVideo(mediaWrap) {
    if (mediaWrap.player) {
        return;
    }

    const playerElement = mediaWrap.querySelector('.player');
    const videoId = playerElement.getAttribute('data-video-id');

    const player = YouTubePlayer(playerElement, {
        videoId,
        playerVars: {
            // autoplay: 0,
            iv_load_policy: 3,
            modestbranding: 1,
            rel: 0,
            showinfo: 0,
        }
    });

    player.on('stateChange', (event) => {
        if (event.data === YT.PlayerState.ENDED) {
            player.seekTo(0);
            // player.pauseVideo();
            player.stopVideo();
        }
    });

    mediaWrap.player = player;
}

function pauseVideo(mediaWrap) {
    if (!mediaWrap.player) {
        return;
    }

    mediaWrap.player.pauseVideo();
    // mediaWrap.player.seekTo(0);

    // mediaWrap.player.stopVideo();
}

function initYtVideo() {
    const firstYtWrap = getYtMediaWrap(document.querySelector('.used-car__media'));

    if (firstYtWrap === null) {
        return;
    }

    if (mainGallery) {
        let mediaWrap = getYtMediaWrap(mainGallery.slides[mainGallery.activeIndex]);

        if (mediaWrap !== null) {
            injectVideo(mediaWrap);
        }

        mainGallery.on('slideChange', function() {
            mediaWrap = getYtMediaWrap(this.slides[this.activeIndex]);

            if (mediaWrap !== null) {
                injectVideo(mediaWrap);
            }

            mediaWrap = getYtMediaWrap(this.slides[this.previousIndex]);

            if (mediaWrap !== null && mediaWrap.player) {
                pauseVideo(mediaWrap);
            }
        });
    } else {
        injectVideo(firstYtWrap);
    }
}

function initContactForm(selector, showFormSelector) {
    const element = document.querySelector(selector);

    if (element === null) {
        return;
    }

    form.init(selector);

    const stepGroup = step.getGroup(element);

    $document.on('click', showFormSelector, (event) => {
        step.change(stepGroup, 'form');

        if (window.gtag) {
            gtag('event', 'click contatta il concessionario', {
                event_category: 'contatto usato',
                event_label: 'sito istituzionale'
            });
        }

        if (dmpApp) {
            dmpApp.aamOnReady(() => {
                if (window.bmwDil) {
                    window.bmwDil.api.signals({
                        c_usato_formName: 'usato_dealer_contact',
                        c_usato_formEvent: 'form_start'
                    });
                }

                dmpApp.dilSubmitData().adformTrack();
            });
        }
    });

    $document.on('form_processing', selector, (event) => {
        const processed = new Promise((resolve, reject) => {
            $document.one('form_processed', selector, (event) => {
                resolve();
            });
        });

        loading.onPromise(processed, stepGroup);
    }).on('form_success', selector, (event) => {
        if (window.gtag) {
            gtag('event', 'click invia', {
                event_category: 'contatto usato',
                event_label: 'sito istituzionale'
            });
        }

        if (dmpApp) {
            dmpApp.aamOnReady(() => {
                if (window.bmwDil) {
                    window.bmwDil.api.signals({
                        c_usato_formName: 'usato_dealer_contact',
                        c_usato_formEvent: 'form_success'
                    });
                }

                dmpApp.dataLayerSet('hmail', event.responseData.hmail);
                dmpApp.dilSubmitData().adformTrack();
                dmpApp.dataLayerUnset('hmail');
            });
        }

        // const iframe = document.createElement('iframe');
        // iframe.src = 'https://5605609.fls.doubleclick.net/activityi;src=5605609;type=tdthku;cat=bmwus0;qty=1;cost=[Revenue];u1=form_contatta_concessionario;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + encodeURIComponent(event.responseData.OrderID) + '?';
        // iframe.width = '1';
        // iframe.height = '1';
        // iframe.frameborder = '0';
        // iframe.style.display = 'none';
        // document.body.appendChild(iframe);

        step.change(stepGroup, 'success');
    }).on('form_failure', selector, (event) => {
        const status = event.responseStatus;

        if (status === 422) {
            // Do nothing, already handled
        } else if (status === 419) {
            // Page expired / CSRF token error -- reload the page and try again
            step.change(stepGroup, 'error_reload');
        } else {
            // Generic error -- try again later
            step.change(stepGroup, 'error');
        }
    });
}

function init() {
    initGallery('.gallery-main', '.gallery-thumbs');

    initYtVideo();

    initContactForm('#contact-form', '.contact-form-btn');
}

export { init };
